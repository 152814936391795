import React from 'react';
import ReactModal from "react-modal";
import NewYearModalContent from './NewYearModalContent';

function CouponModal(props) {

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            minHeight: "250px",
            minWidth: "250px",
            maxWidth: "600px",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: "5000",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            borderRadius: "8px",
            border: "0"
        },
    };

    return (
        <div>
            <ReactModal
                isOpen={props.openModal}
                onRequestClose={() => {
                    props.handleSetOpenModal(false)
                }}
                style={customModalStyles}
                contentLabel="Praznični popusti"
            >
                <NewYearModalContent
                    handleSetOpenModal={props.handleSetOpenModal}
                />
            </ReactModal>
        </div>
    );
}

export default CouponModal;