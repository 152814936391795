import React from 'react';
import {Link, useStaticQuery, graphql} from "gatsby";
import BackgroundImage from 'gatsby-background-image'

function MineServices(props) {
    const data = useStaticQuery(
        graphql`
            query {
                topleft: file(relativePath: { eq: "rudnik/vodeni-ogledi-glavnega-rova-v-rudniku-litija.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                
                topright: file(relativePath: { eq: "rudnik/vodeni-ogledi-izvozni-rov.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                bottomleft: file(relativePath: { eq: "rudnik/skrat-3.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                bottomright: file(relativePath: { eq: "rudnik/kulinarika-v-rudniku-sitarjevec-sitarjevska-malca-in-skratov-smorn.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const topLeftImage = data.topleft.childImageSharp.fluid;
    const topRightImage = data.topright.childImageSharp.fluid;
    const bottomLeftImage = data.bottomleft.childImageSharp.fluid;
    const bottomRightImage = data.bottomright.childImageSharp.fluid;

    return (
        <div className="rn-blog-area ptb--120 bg_color--5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12 mx-auto">
                        <div className="section-title text-center">
                            <h2 className="title">Pestra ponudba doživetij Rudnika Sitarjevec</h2>
                            <p className="description">Rudnik Sitarjevec Litija je naravoslovna in zgodovinska učilnica v naravnem okolju. Zbuja radovednost in ponuja svojevrstno doživetje, hkrati pa skozi zgodbe obuja
                                zavedanje in utrjuje vrednote o naši bogati dediščini, ki postaja svetovno znana.</p>
                        </div>
                    </div>
                </div>

                <div className="row mt--30 mt_sm--40">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/obisci-rudnik/glavni-rov">
                            <div className="im_box mt--30">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={topLeftImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Vodeni ogledi
                                            </div>
                                            <h4 className="title">
                                                <Link to="/obisci-rudnik/glavni-rov">Vodeni ogledi Glavnega rova</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/obisci-rudnik/glavni-rov" className="rn-btn btn-opacity">Preberi več</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/obisci-rudnik/izvozni-rov">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={topRightImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Vodeni ogledi
                                            </div>
                                            <h4 className="title">
                                                <Link to="/obisci-rudnik/izvozni-rov">Vodeni ogledi Izvoznega rova</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/obisci-rudnik/izvozni-rov" className="rn-btn btn-opacity">Preberi več</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/obisci-rudnik/animirana-vodenja">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={bottomLeftImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Vodeni ogledi
                                            </div>
                                            <h4 className="title">
                                                <Link to="/obisci-rudnik/animirana-vodenja">Animirano vodenje za družine s Škratom</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/obisci-rudnik/animirana-vodenja" className="rn-btn btn-opacity">Preberi več</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Link to="/obisci-rudnik/kulinarika">
                            <div className="im_box mt--30 service-details-container">
                                <div className="thumbnail">
                                    <BackgroundImage Tag="div" className="service-details-content" fluid={bottomRightImage} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                Vodeni ogledi
                                            </div>
                                            <h4 className="title">
                                                <Link to="/obisci-rudnik/kulinarika">Kulinarika</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to="/obisci-rudnik/kulinarika" className="rn-btn btn-opacity">Preberi več</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineServices;