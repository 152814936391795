import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function NewsSimple() {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Novice in obvestila</span>
                                    <h2 className="title">Aktualne novice iz litijskega podzemlja</h2>
                                </div>
                                <div className="row mt--30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Rudniku Sitarjevec Litija – Petek in sobota, 11. - 12. oktober 2024
                                                            </p>

                                                            <h3>
                                                                Etnološki dogodek pred rudnikom Sitarjevec Litija -
                                                                pridobivanje železa na železnodobni način
                                                            </h3>

                                                            <p className="mb-40px fontWeight600">
                                                                <strong>Mentor: Peter Urbanija, mentor geološkega
                                                                    krožka </strong> na OŠ Staneta Žagarja v Lipnici
                                                                Od 9:00 do 14:00.
                                                            </p>

                                                            <p className="mb-15px text-primary">
                                                                Pridružite se nam na edinstvenem etnološkem dogodku pred
                                                                rudnikom Sitarjevec Litija, kjer bomo
                                                                prikazali, kako so v železni dobi pridobivali železovo
                                                                rudo.
                                                            </p>

                                                            <p className="mb-15px">
                                                                Celoten postopek boste lahko v soboto, 12. 10.
                                                                spremljali na ploščadi pred rudnikom med 9:00 in
                                                                14 uro. Ob 11. in 12. uri bo kulturni program s keltsko
                                                                glasbo. Za prijetno vzdušje bosta poskrbela
                                                                Ana Novak in Gašper Šinkovec, člana etno zasedbe Noreia.
                                                            </p>

                                                            <p className="mb-15px">
                                                                Dogodek bo dodatno popestrila ponudba izbranih
                                                                rokodelcev ter kulinarična ponudba.
                                                                V času dogodka bo možen ogled rudnika.
                                                            </p>

                                                            <p className="mb-15px">
                                                                Za več informacij nas kontaktirajte na
                                                                info@visitlitija.si ali na 051 312 739 (Mojca Hauptman).
                                                                Vabljeni, da se nam pridružite in doživite dediščino
                                                                naših prednikov na prav poseben način!
                                                            </p>

                                                                <a
                                                                    href="https://spletnesi.fra1.cdn.digitaloceanspaces.com/rudnik-sitarjevec/Etnolos%CC%8Cka%20dogodka%20pred%20rudnikom%20Sitarjevec%20Litija%20(1).pdf"
                                                                    className="btn-default size-md mt-2"
                                                                    target="_blank"
                                                                >
                                                                   Preberi več
                                                                </a>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/rudnik/etnoloski-dogodek.png"
                                                                    alt="Etnološki dogodek 2024"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSimple;