import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function Characteristics(props) {
    return (
        <div>
            {/* Start Service Area  */}
            <div className="service-area ptb--120 bg_color--5 mine-characteristics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="title">
                                    Razišči posebnosti <br /> Rudnika Sitarjevec
                                </h2>
                                <p className="description">
                                    Edinstven vpogled v raznolikost geološke
                                    dediščine
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper d-flex">
                                {/*number 1 */}

                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center h-100">
                                            <StaticImage
                                                src="../../assets/images/features-icons/1-60-vrst-mineralov.png"
                                                alt="Ikona za 60 različnih vrst mineralov v litijskem rudniku"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    60 različnih vrst mineralov
                                                </h3>
                                                <p>
                                                    Rudnik Sitarjevec sodi med
                                                    rudišča z izjemno mineraloško
                                                    pestrostjo, morda največjo v
                                                    Evropi.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                {/*number 2 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center h-100">
                                            <StaticImage
                                                src="../../assets/images/features-icons/2-limonitni-kapniki.png"
                                                alt="Liminitni kapniki v Rudniku Litija Sitarjevec"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Limonitni kapniki
                                                </h3>
                                                <p>
                                                    Rudnik odlikujejo izjemne
                                                    limonitne kapniške strukture,
                                                    edinstvene v Evropi in morda
                                                    najhitreje rastoče na svetu.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 3 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/3-barvitost-rudnika.png"
                                                alt="Barvitost v Rudniku Litije"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Izjemna barvitost rudnika
                                                </h3>
                                                <p>
                                                    Izrazita barvitost kamnin in
                                                    mineralov ter njihovi bleščeči
                                                    odsevi očarajo obiskovalce in
                                                    navdihujejo umetnike.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 4 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/4-gorotvorni-procesi.png"
                                                alt="Gorotvorni procesin in tektonska premikanja v Rudniku Litije"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Gorotvorni procesi in tektonska
                                                    drsa
                                                </h3>
                                                <p>
                                                    Znamenja 300 milijonov let
                                                    trajajočega, skoraj neverjetnega
                                                    tektonskega dogajanja.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 5 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/5-paleookolje.png"
                                                alt="Gorotvorni procesin in tektonska premikanja v Rudniku Litije"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content h-100">
                                                <h3 className="title text-uppercase">
                                                    {" "}
                                                    Rekonstrukcija paleookolja
                                                </h3>
                                                <p>
                                                    Vpogled v skrivnosten in
                                                    navidezno pravljičen svet
                                                    nastanka kamnin Rudnika
                                                    Sitarjevec.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 6 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/6-najstarejsi-rudnik.png"
                                                alt="Najstarejši Rudnik v Litiji "
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Najstarejši slovenski rudnik?
                                                </h3>
                                                <p>
                                                    Arheološka najdišča v bližnji
                                                    okolici kažejo na to, da so v
                                                    Litiji rudarili že pred 4000
                                                    leti.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 7 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/7-mali-podkovnjak.png"
                                                alt="Mali podkovnjak v rudniku Sitarjevec"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Mali podkovnjak - Rhinolophus
                                                    hipposideros
                                                </h3>
                                                <p>
                                                    Netopirji so edini sesalci, ki
                                                    letijo. Rudnik je že od nekdaj
                                                    njihovo bivališče.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {/*number 8 */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/8-topilnica.png"
                                                alt="Litijska topilnica"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />

                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Topilnica in čebelarska pravda
                                                </h3>
                                                <p>
                                                    Boj litijskih čebelarjev proti
                                                    Topilnici Litija. Je prva
                                                    uspešna ekološka pravda v
                                                    Sloveniji.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-center mine-characteristics-element">
                                    <Link to={"/razisci-rudnik-sitarjevec"}>
                                        <div className="service service__style--2 text-center">
                                            <StaticImage
                                                src="../../assets/images/features-icons/9-srebrnik.png"
                                                alt="Litijski srebrnik"
                                                loading="lazy"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-100"
                                            />
                                            <div className="content">
                                                <h3 className="title text-uppercase">
                                                    Srebrnik - Litijski Tolar
                                                </h3>
                                                <p>
                                                    V litijski topilnici so
                                                    pridobili srebro iz svinčeve
                                                    rude iz katere so izdelali
                                                    spominske srebrnike - litijske
                                                    tolarje.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Characteristics;
