import React from "react";
import { Link, navigate } from "gatsby";

function CouponModalContent(props) {

    function closeModalAndRedirect(){
        props.handleSetOpenModal(false);
        navigate('/kontakt-in-informacije');
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center">
                        <span className="subtitle" />
                        <h3 className="title px-4 d-block">
                            <span className="">Možnost koriščenja turističnih vrednostih bonov </span>

                            <span className="text-primary">
                                tudi v Rudniku Sitarjevec Litija!
                            </span>
                        </h3>
                        <p className="mt--40 description d-block px-4">
                            <span className="">
                                Minimalna vrednost unovčljivosti bona je <span className="font-weight-bold colored-link">30,00€</span>. Več
                                informacij o rezervaciji ogleda lahko najdete
                            </span>
                            <span className="ml-1 colored-link font-weight-bold is-clickable" onClick={() => {closeModalAndRedirect()}}>
                                tukaj.
                            </span>
                        </p>

                        <div className="slide-btn mt--40 d-flex flex-column">
                            <div className="slide-btn">
                                <a className="btn-default is-clickable"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeModalAndRedirect()
                                    }}
                                >
                                    REZERVIRAJ TERMIN IN IZKORISTI BON!
                                </a>
                            </div>
                            <small onClick={() => {props.handleSetOpenModal(false)}} className="mt-4 mb-1 is-clickable">Zapri okno</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CouponModalContent;
